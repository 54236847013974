import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layouts/Default"

import Reviews from "../../components/Reviews"
import FaqList from "../../components/common/FaqList"
import Container from "../../components/containers/Container"

const Page = ({ data, location }) => {
  return (
    <Layout
      title="FAQ zu Dierda Finanzdienstleistungen"
      description="Wir sammeln und beantworten in diesem Bereich Deine häufigsten Fragen."
      location={location}
    >
      <Container>
        <div className="mb-12 text-center">
          <div className="mb-5">
            <nav className="sm:hidden">
              <Link
                to="/info/"
                className="flex items-center text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 mr-1 -ml-1 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Zum Infocenter
              </Link>
            </nav>
            <nav className="items-center hidden mx-auto text-sm font-medium leading-5 sm:inline-flex">
              <Link
                to="/info/"
                className="text-gray-500 transition duration-150 ease-in-out hover:text-gray-700"
              >
                Infocenter
              </Link>
              {/* Heroicon name: chevron-right */}
              <svg
                className="flex-shrink-0 w-5 h-5 mx-2 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              FAQ
            </nav>
          </div>
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-blue-500 sm:text-4xl sm:leading-10">
            FAQ
            <br />
            <span className="text-gray-900 ">Deine häufigsten Fragen</span>
          </h1>
          <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Wir sammeln und beantworten in diesem Bereich Deine häufigsten
            Fragen.
          </p>
        </div>
        <div className="max-w-xl mx-auto">
          <FaqList />
        </div>
      </Container>

      <Reviews />
    </Layout>
  )
}

export default Page
