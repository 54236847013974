import React from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronRightIcon } from "@heroicons/react/solid"

const Accordion = ({ title, text, className }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div className={`text-lg leading-relaxed ${className ? className : ""}`}>
          <Disclosure.Button as="dt" className="flex items-start justify-between w-full p-3 text-left text-gray-900 bg-gray-200 rounded-lg">
            {title}
            <ChevronRightIcon
              className={`h-6 w-6 ${open ? "transform rotate-90" : ""}`}
            />
          </Disclosure.Button>

          <Disclosure.Panel unmount={false} as="dd" className="p-3 mt-2 text-gray-700">
            {text}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>

    /*  <div>
       <dt className="text-lg leading-7 ">
         <button
           className="flex items-start justify-between w-full p-3 text-left text-gray-900 bg-gray-100 rounded-lg"
           onClick={() => setIsOn(!isOn)}
         >
           <span className="font-medium text-gray-900">{title}</span>
           <span className="flex items-center ml-6 h-7">
             <svg
               className={`h-6 w-6 transform ${isOn ? `-rotate-180` : `rotate-0`
                 }`}
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
             >
               <path
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth={2}
                 d="M19 9l-7 7-7-7"
               />
             </svg>
           </span>
         </button>
       </dt>
       <Transition
         show={isOn}
         enter="ease-out duration-100"
         enterFrom="opacity-0"
         enterTo="opacity-100"
         leave="ease-in duration-100"
         leaveFrom="opacity-100"
         leaveTo="opacity-0"
         unmount={false}
       >
         <dd className="p-3 mt-2 text-gray-700">{text}</dd>
       </Transition>
     </div> */
  )
}

export default Accordion
