import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Accordion from "./Accordion"

const FaqList = () => {
  const data = useStaticQuery(graphql`
    query {
      allFaq {
        nodes {
          question
          answer
        }
      }
    }
  `)

  return (
    <dl>
      {data.allFaq.nodes.map((faq, i) => {
        return (
            <Accordion title={faq.question} text={faq.answer} className="mb-6" key={i} />
        )
      })}
    </dl>
  )
}

export default FaqList
